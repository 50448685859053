@charset 'utf-8';

@import 'settings';
@import 'foundation';

// Global styles
@include foundation-global-styles;
//@include foundation-forms;
//@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
//@include foundation-button-group;
//@include foundation-close-button;
//@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
//@include foundation-table;
// Basic components
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-dropdown;
//@include foundation-pagination;
//@include foundation-tooltip;

// Containers
// @include foundation-accordion;
//@include foundation-media-object;
// @include foundation-orbit;
// @include foundation-responsive-embed;
// @include foundation-tabs;
// @include foundation-thumbnail;

// Menu-based containers
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-accordion-menu;
// @include foundation-drilldown-menu;
// @include foundation-dropdown-menu;

// Layout components
// @include foundation-off-canvas;
// @include foundation-reveal;
// @include foundation-sticky;
// @include foundation-title-bar;
// @include foundation-top-bar;

// Helpers
//@include foundation-float-classes;
//@include foundation-flex-classes;
//@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
// @include motion-ui-transitions;
// @include motion-ui-animations;


// Custom CSS
.full-bg {background: url(../img/bg-home.png) no-repeat; background-size: cover; background-attachment: fixed;

	.grid-container {max-width: 75rem;}
	.logo {padding-top: 2rem;}
	.cell {text-align: center;}
	a {display: inline-block;}
	.logo-img {max-width: 12rem;
		@include breakpoint(medium) {max-width: 20rem}
	}
	.header-img {max-width: 19rem;
		@include breakpoint(medium) {max-width: 20rem}
	}
	
	.button {border-radius: 1.5rem; font-size: 1rem; font-weight: bold; display: block; width: 100%; margin: 1rem 0;
		&.green {background: #51BE30 }
		&.red {background: #C73738}
	}

}